body {
  color: #333333;
}

*:focus {
  outline: none !important;
}
::placeholder {
  color: #c5c5d0 !important;
}
